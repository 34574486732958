@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hero {
    height: calc(100vh - 176px);
  }

  .text-shadow-normal {
    text-shadow: 4px 4px 10px rgb(0 0 0 / 60%);
  }

  .animate-float {
    animation: float 3s infinite;
  }

  .animate-shadow-float {
    animation: shadow-float 3s infinite;
    transform-origin: center bottom;
    left: 50%;
  }

  .animate-backdrop {
    animation: backdrop_roll linear 80s infinite;
  }

  .repeater {
    width: calc(100% + 1024px);
    height: calc(100% + 1024px);
    max-width: 1000%;
    max-height: 1000%;
    top: -1024px
  }

  .font-style-game {
    font-family: 'Lilita One';
    text-shadow: -2px 3px 0px #000, 2px 3px 0px #000, 1px -1px 0px #000, -1px -1px 0px #000;
  }

  .font-style-game-small {
    font-family: 'Lilita One';
    text-shadow: -2px 2px 0px #000, 2px 2px 0px #000, 1px -1px 0px #000, -1px -1px 0px #000;
  }

  .font-style-game-regular {
    font-family: 'Lilita One';
  }

  .font-style-game-title {
    font-family: 'Lilita One';
    font-weight: 500;
    text-shadow: -2px 4px 0px #000, 4px 4px 0px #000;
    -webkit-text-stroke: #000;
    -webkit-text-stroke-width: 2px;
  }

  .fade-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .fade-overlay-to-top-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(15,23,42,1), rgba(15,23,42,0));
  }

  .fade-overlay-to-bottom-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(15,23,42,1), rgba(15,23,42,0));
  }

  .fade-overlay-to-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(15,23,42,1), 30%, rgba(15,23,42,0.7), 40%, rgba(15,23,42,0.3));
  }

  .fade-overlay-to-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(15,23,42,1), 10%, rgba(15,23,42,0.7), 30%, rgba(15,23,42,0));
  }

  .fade-overlay-to-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(320deg, rgba(15,23,42,0.8), 50%, rgba(15,23,42,1), 50%, rgba(15,23,42,0), 100%, rgba(15,23,42,0));
  }

  .fade-overlay-to-right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(40deg, rgba(15,23,42,0.8), 50%, rgba(15,23,42,1), 50%, rgba(15,23,42,0), 100%, rgba(15,23,42,0));
  }

  @keyframes float {
    0% {
      transform: translatey(-25px);
    }
    50% {
      transform: translatey(0px);
    }
    100% {
      transform: translatey(-25px);
    }
  }

  @keyframes shadow-float {
    0% {
      transform: translateX(-50%) scale(1);
    }
    50% {
      transform: translateX(-50%) scale(0.75);
    }
    100% {
      transform: translateX(-50%) scale(1);
    }
  }

  @keyframes backdrop_roll {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-1024px, 1024px);
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
